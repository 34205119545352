<template>
  <b-row id="settings-row" class="gx-2">
    <b-col id="setting-list-col" cols="auto">
      <div class="box full-box">
        <h1 class="box-title">應用設置 / Settings</h1>

        <b-list-group>
          <!-- <b-list-group-item
            :active="activeSetting == 'profile'"
            href="#"
            @click.prevent="selectSetting('profile')"
            class="no-underline d-flex align-items-center"
          >
            用戶資料
            <b-icon class="ml-auto" icon="chevron-right"></b-icon>
          </b-list-group-item> -->
          <b-list-group-item
            :active="activeSetting == setting_key"
            v-for="(name, setting_key) in title"
            :key="'setting-' + setting_key"
            href="#"
            @click.prevent="selectSetting(setting_key)"
            class="no-underline d-flex align-items-center"
          >
            {{ name }}

            <b-icon class="ml-auto" icon="chevron-right"></b-icon>
          </b-list-group-item>

          <!-- <b-list-group-item
            href="#"
            :active="activeSetting == 'locations'"
            @click.prevent="selectSetting('locations')"
            class="no-underline d-flex align-items-center"
            >中英對照表
            <b-icon class="ml-auto" icon="chevron-right"></b-icon>
          </b-list-group-item>

          <b-list-group-item
            href="#"
            :active="activeSetting == 'order_history'"
            @click.prevent="selectSetting('order_history')"
            class="no-underline d-flex align-items-center"
            >訂單歷史
            <b-icon class="ml-auto" icon="chevron-right"></b-icon>
          </b-list-group-item> -->
        </b-list-group>

        <template v-if="activeSetting == 'locations'">
          <h2 class="box-title mt-5">新增中英對照表類別</h2>
          <div class="new-box box gray-box ml-n4 mr-n3 mb-4 py-3">
            <div class="form-inline flex-nowrap">
              <b-form-input
                placeholder="新增類別"
                class="mr-2"
                v-model="newSetting"
              />
              <b-button
                variant="primary"
                @click="setLocationCategory(null, newSetting)"
              >
                Add
              </b-button>
            </div>
          </div>

          <div
            class="form-inline flex-nowrap mb-2"
            v-for="(value, s_index) in location_categories"
            :key="`setting-location-cate-${s_index}`"
          >
            <b-form-input
              class="mr-1"
              placeholder="中英對照表類別"
              v-model="value.name"
            />
            <b-button
              variant="link"
              @click="setLocationCategory(value.categoryId, value.name)"
            >
              <b-spinner label="Spinning" small v-if="false"></b-spinner>
              <b-icon icon="check" v-else></b-icon>
            </b-button>
            <b-button
              variant="link"
              class="px-1"
              @click="removeLocationCategory(value.categoryId)"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </div>
        </template>
      </div>
    </b-col>
    <b-col>
      <div class="box full-box">
        <template v-if="activeSetting == 'locations'">
          <h2 class="box-title">{{ title[activeSetting] }}</h2>

          <div class="new-box box gray-box ml-n4 mr-n3 mb-4 pl-4">
            <SearchInput
              key="location-input-search"
              index="Locations"
              title="address"
              subtitle="tags"
              placeholder="搜尋"
              :onClick="getLocation"
            />
          </div>
          <div class="form-inline mb-4">
            <b-form-input
              class="mr-1"
              placeholder="地址"
              v-model="location.address"
            />
            <b-form-select
              class="mr-2"
              v-model="location.category"
              :options="Object.values(location_categories).map((l) => l.name)"
            >
            </b-form-select>
          </div>
          <div
            class="form-inline mb-2"
            v-for="(value, s_index) in location.tags"
            :key="`setting-${s_index}`"
          >
            <b-form-input
              class="mr-1"
              :value="value"
              @input.native="updateTag(s_index, $event.currentTarget.value)"
            />
            <b-button variant="link" class="px-1" @click="removeTag(s_index)">
              <b-icon icon="x"></b-icon>
            </b-button>
          </div>

          <div class="location-input-control mt-3">
            <b-row align-v="center">
              <b-col>
                <a href="#" @click.prevent="addTag">新增標籤</a>
              </b-col>
              <b-col class="text-right">
                <b-button variant="primary" @click="setLocation">
                  {{ this.location.locationId ? "更新" : "新增" }}
                </b-button>
                <b-button
                  variant="danger"
                  class="ml-2"
                  v-if="this.location.locationId != null"
                  @click="deleteLocation"
                >
                  刪除
                </b-button>
              </b-col>
            </b-row>
          </div>
        </template>
        <template v-else-if="this.duel_language.includes(activeSetting)">
          <h2 class="box-title">{{ title[activeSetting] }}</h2>

          <div class="new-box box gray-box ml-n4 mr-3 mb-4 pl-4">
            <label :for="`input-${activeSetting}`"
              >新增{{ title[activeSetting] }}</label
            >
            <div class="form-inline">
              <b-form-input class="mr-2" v-model="newSetting['en']" />
              <b-form-input class="mr-2" v-model="newSetting['zh']" />
              <b-button variant="primary" @click="addNewSetting">Add</b-button>
            </div>
          </div>

          <div
            class="form-inline mb-2"
            v-for="(value, s_index) in settings[activeSetting]"
            :key="`setting-${s_index}`"
          >
            <b-form-input
              class="mr-1"
              v-model="settings[activeSetting][s_index]['en']"
            />
            <b-form-input
              class="mr-1"
              v-model="settings[activeSetting][s_index]['zh']"
            />
            <b-button variant="link" @click="updateSetting(s_index)">
              <b-spinner
                label="Spinning"
                small
                v-if="settingUpdating[activeSetting][s_index]"
              ></b-spinner>
              <b-icon icon="check" v-else></b-icon>
            </b-button>
            <b-button
              variant="link"
              class="px-1"
              @click="removeSetting(s_index)"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </div>
        </template>
        <template v-else-if="activeSetting == 'order_history'">
          <h2 class="box-title">{{ title[activeSetting] }}</h2>

          <b-list-group>
            <b-list-group-item
              class="history-order-item"
              v-for="o in order_history"
              :key="`order-history-${o.orderId}`"
            >
              <div class="d-flex w-100 justify-content-between">
                <div>
                  <h6 class="mb-1 d-flex align-items-center">
                    <template v-if="o.customer.name"
                      >{{ o.customer.name }} -</template
                    >
                    {{ o.customer.phone }}

                    <small class="order-control">
                      <OrderModal
                        class="p-0 ml-3"
                        button-size="sm"
                        button-variant="link"
                        :order="o"
                      >
                        edit
                      </OrderModal>
                      <a
                        class="ml-1 btn btn-sm p-0 no-underline delete-order"
                        @click.prevent="unsetOrder(o)"
                        href="#"
                        >delete</a
                      >
                    </small>
                  </h6>

                  <small> {{ o.date }} {{ o.time }} </small>
                </div>
                <div class="order-meta">
                  <p class="mb-1">
                    更新
                    <em>by</em> {{ o.updatedBy }} <em>at</em>
                    {{
                      $_.isString(o.updatedAt)
                        ? moment(
                            o.updatedAt,
                            "ddd MMM DD YYYY HH:mm:ss"
                          ).format("MM/DD/YY HH:mm")
                        : moment(o.updatedAt.seconds * 1000).format(
                            "MM/DD/YY HH:mm"
                          )
                    }}
                  </p>
                  <p class="mb-1">
                    落單
                    <em>by</em> {{ o.createdBy }} <em>at</em>
                    {{
                      $_.isString(o.createdAt)
                        ? moment(
                            o.createdAt,
                            "ddd MMM DD YYYY HH:mm:ss"
                          ).format("MM/DD/YY HH:mm")
                        : moment(o.createdAt.seconds * 1000).format(
                            "MM/DD/YY HH:mm"
                          )
                    }}
                  </p>
                  <p v-if="o.statusChangedBy" class="mb-1">
                    Status Changed
                    <em>by</em> {{ o.statusChangedBy }} 
                  </p>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </template>
        <template v-else-if="activeSetting == 'profile'">
          <h2 class="box-title">{{ title[activeSetting] }}</h2>

          <b-form id="profile-form" @submit.prevent="saveProfile">
            <b-form-group label="Display name:" label-for="input-display-name">
              <b-form-input
                id="input-display-name"
                v-model="profileForm.name"
                type="text"
                required
              ></b-form-input>
            </b-form-group>

            <b-button type="submit" variant="primary">Save</b-button>
          </b-form>
        </template>
        <template v-else-if="activeSetting">
          <h2 class="box-title">{{ title[activeSetting] }}</h2>

          <div class="new-box box gray-box ml-n4 mr-3 mb-4 pl-4">
            <label :for="`input-${activeSetting}`"
              >新增{{ title[activeSetting] }}</label
            >
            <div class="form-inline">
              <b-form-input class="mr-2" v-model="newSetting" />
              <b-button variant="primary" @click="addNewSetting">Add</b-button>
            </div>
          </div>

          <div
            class="form-inline mb-2"
            v-for="(value, s_index) in settings[activeSetting]"
            :key="`setting-${s_index}`"
          >
            <b-form-input
              class="mr-1"
              v-model="settings[activeSetting][s_index]"
            />
            <b-button variant="link" @click="updateSetting(s_index)">
              <b-spinner
                label="Spinning"
                small
                v-if="settingUpdating[activeSetting][s_index]"
              ></b-spinner>
              <b-icon icon="check" v-else></b-icon>
            </b-button>
            <b-button
              variant="link"
              class="px-1"
              @click="removeSetting(s_index)"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </div>
        </template>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { cloneDeep } from "lodash";

import SearchInput from "@/components/SearchInput.vue";
import OrderModal from "@/components/OrderModal.vue";

export default {
  name: "Settings",
  components: { SearchInput, OrderModal },
  data() {
    return {
      newSetting: {},
      activeSetting: null,
      title: {
        profile: "用戶資料",
        status: "訂單狀況",
        frequently_use_instructions: "司機指示",
        frequently_used_remark: "備註",
        reply_customer: "回覆客戶",
        other_luggages: "其他行李",
        payment_methods: "付款方法",
        common_questions: "特殊要求",
        locations: "中英對照表",
        order_history: "最近更改歷史",
      },
      duel_language: ["common_questions", "other_luggages"],
      settings: this.$store.state.settings.settings,
      settingUpdating: {
        frequently_use_instructions: [],
        frequently_used_remark: [],
        other_luggages: [],
        payment_methods: [],
        status: [],
        common_questions: [],
        reply_customer: [],
      },
      location: {
        address: "",
        category: "",
        tags: [""],
      },
      profileForm: {},
    };
  },
  computed: {
    ...mapState({
      stored_settings: (state) => state.settings.settings,
      location_categories: (state) => state.locations.categories,
      selected_location: (state) => state.locations.selectedLocation,
      order_history: (state) => state.orders.orders,
      user: (state) => {
        return {
          profile: state.user.userProfile,
          userId: state.user.userId,
        };
      },
    }),
  },
  watch: {
    user: {
      immediate: true,
      handler(nv) {
        this.profileForm = cloneDeep(this.user.profile);
      },
    },
    stored_settings(nv) {
      this.settings = cloneDeep(this.stored_settings);
    },
    selected_location(nv) {
      this.location = cloneDeep(this.selected_location);
    },
    activeSetting(nv) {
      if (nv == "order_history") {
        this.getRecentCreatedOrders();
      }

      // If the setting support duel language, it will be stored in a map/object
      if (this.duel_language.includes(nv)) {
        this.newSetting = {};
      } else {
        this.newSetting = null;
      }
    },
  },
  methods: {
    ...mapActions({
      getOrders: "orders/get",
      deleteOrder: "orders/delete",
      setUserProfile: "user/updateUserProfile",
    }),
    getRecentCreatedOrders() {
      this.getOrders({
        realtime: true,
        orderBy: "updatedAt",
        order: "desc",
        count: 50,
        filters: {
          updatedAt: new Date(),
        },
      });
    },
    selectSetting(key) {
      this.activeSetting = key;
    },
    addNewSetting() {
      const currentSettings = this.settings[this.activeSetting] || [];
      currentSettings.unshift(this.newSetting);
      this.settings[this.activeSetting] = currentSettings;

      this.setSettings();
      this.newSetting = "";
    },
    addTag() {
      this.location.tags.push("");
    },
    updateTag(index, val) {
      this.location.tags[index] = val;
    },
    removeTag(index) {
      this.location.tags.splice(index, 1);
    },
    async saveProfile() {
      await this.setUserProfile({
        userId: this.user.userId,
        userProfile: this.profileForm,
      });

      this.$notify({
        text: "更新成功",
        type: "success",
      });
    },
    async setLocation() {
      await this.$store.dispatch("locations/setLocation", {
        locationId: this.location.locationId,
        data: this.location,
      });

      this.$notify({
        text: "更新成功",
        type: "success",
      });
    },
    async setLocationCategory(categoryId, name) {
      await this.$store.dispatch("locations/setLocationCategory", {
        categoryId,
        name,
      });

      this.$notify({
        text: "更新成功",
        type: "success",
      });
    },
    async deleteLocation() {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete this location? (It cannot be undone)"
        )
        .then(async (value) => {
          await this.$store.dispatch(
            "locations/removeLocation",
            this.location.locationId
          );

          this.location = {
            address: "",
            category: "",
            tags: [""],
          };

          this.$notify({
            text: "Location removed successfully",
            type: "success",
          });
        });
    },
    async removeLocationCategory(categoryId) {
      await this.$store.dispatch(
        "locations/removeLocationCategory",
        categoryId
      );
    },
    async updateSetting(s_index) {
      this.$set(this.settingUpdating[this.activeSetting], s_index, true);
      // this.settingUpdating[this.activeSetting][s_index] = true;
      await this.setSettings();

      setTimeout(() => {
        this.$set(this.settingUpdating[this.activeSetting], s_index, false);
      }, 300);
    },
    removeSetting(s_index) {
      this.settings[this.activeSetting].splice(s_index, 1);
      this.setSettings();
    },
    async setSettings() {
      const _settings = Object.assign({}, this.settings);

      await this.$store.dispatch("settings/set", _settings);

      this.$notify({
        text: "更新成功",
        type: "success",
      });
    },
    getLocation(locationId) {
      this.$store.dispatch("locations/getLocation", locationId);
    },
    unsetOrder(order) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete this order? (It cannot be undone)"
        )
        .then(async (value) => {
          if (value) {
            await this.deleteOrder(order.orderId);

            this.$notify({
              text: "Order removed successfully",
              type: "success",
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#settings-row {
  height: 100%;
  > div {
    height: 100%;
  }
}
#setting-list-col {
  width: 400px;
}

.new-box {
  border-radius: 0;
}

.form-inline {
  input[type="text"] {
    width: 450px;
  }

  .bi-check,
  .bi-x {
    transform: scale(1.5);
  }
}
.location-input-control {
  max-width: 450px;
}

.alert {
  max-width: 450px;
}

.full-box {
  overflow-y: auto;
}

.history-order-item {
  &:hover {
    .order-control {
      opacity: 1;
    }
  }

  .order-control {
    opacity: 0;
    transition: 0.3s opacity;
    .btn {
      font-size: 12.8px;
    }
  }
  .delete-order {
    color: red;
  }
  .order-meta {
    font-size: 12px;
    text-align: right;
  }
}

#profile-form {
  max-width: 450px;
}
</style>
